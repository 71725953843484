.chat-page-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  max-height: 100%;

  .chat-box {
    box-shadow: none;
  }
}

.create-document-modal {
  width: 450px !important;

  .create-document-wrap {
    padding: 10px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: center;
    align-items: center;
  }

  .outlined-basic {
    width: 350px;
  }
}
