.chat-item-menu {
  cursor: pointer;
  margin-bottom: 10px;
}

.MuiAppBar-root {
  z-index: 998 !important;
}

.chat-item-menu {
  padding: 5px;

  &:hover {
    background: rgba(0, 0, 0, 0.08);
    border-radius: 6px;
  }

  &.active {
    background: rgba(0, 0, 0, 0.08);
    border-radius: 6px;
    p {
      font-weight: bold;
    }
  }
}
