html,
body,
#root {
  overflow: hidden;
  ::-webkit-scrollbar {
    background: #fff;
    border: 1px solid transparent;
    height: 4px;
    width: 4px;
  }

  ::-webkit-scrollbar-thumb {
    background: #376fd0;
    border-radius: 10px;
  }
}

.cursor-pointer {
  cursor: pointer;
}

.ant-segmented-item {
  min-width: 100px;
}

.ant-modal-title {
  text-align: center;
}

.create-media-modal {
  .ant-space-item:nth-child(1) {
    width: 100%;
  }
}

.user-modal {
  width: 450px !important;
}
.media-list-modal {
  width: 100% !important;
}
.quick-update-expiration-modal {
  width: 450px !important;
  .ant-modal-body {
    height: 400px;
  }
}

.ant-modal-mask {
  z-index: 1200 !important;
}

.ant-modal-wrap {
  z-index: 1300 !important;
}

.ant-select-dropdown,
.ant-picker-dropdown {
  z-index: 1400 !important;
}

.limit-2-lines {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.ellipsis {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.quick-update-expiration-modal__date-picker .ant-picker-panel-container {
  margin-left: 60px;
}

.ant-collapse-content-box {
  padding: 0 !important;
}
