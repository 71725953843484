.document-form {
  .ant-form-item-control-input {
    min-width: 350px;
  }

  .ant-form-item {
    margin-bottom: 10px;
  }

  input {
    height: 40px;
  }

  .ant-select-selector {
    width: 350px;
  }
}

.card-document-item {
  box-shadow: none !important;

  .ant-card-body {
    padding: 10px 0;
  }
  h3,
  h4,
  p {
    margin: 0;
  }

  .item-updated-time {
    font-size: 12px;
    color: #8c8c8c;
  }

  .show-more-button {
    color: #8c8c8c;
    cursor: pointer;
    font-size: 14px;
    text-decoration: underline;
  }
}
